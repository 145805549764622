import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Provider, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../assets/styles/style.scss";

import store from "../redux/store";
import AppContent from "./_app-content";
import Loader from "../components/loader/loader";
import { configsMain_req } from "../api/api";
import { mainBlockData } from "../redux/main.slice";

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (
      router?.query?.minPrice?.length < 1 ||
      router?.query?.maxPrice?.length < 1
    ) {
      setLoad(true);

      setTimeout(() => {
        setLoad(false);
      }, 3000);
    }

    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, [router?.pathname, router?.query?.id, router.query?.item]);

  const shop = router.pathname?.slice(1, 5);

  useEffect(() => {
    if (
      router.pathname !== "/all/[id]" &&
      router.pathname !== "/product" &&
      router.pathname === shop
    ) {
      sessionStorage.removeItem("min");
      sessionStorage.removeItem("max");
      localStorage.removeItem("min");
      localStorage.removeItem("max");
    }
  }, [router]);

  return (
    <Provider store={store}>
      {load ? (
        <Loader />
      ) : (
        <>
          <AppContent
            Component={Component}
            pageProps={pageProps}
          />

          <ToastContainer />
        </>
      )}
    </Provider>
  );
}

MyApp.getServerSideProps = (context) => ({
  props: {},
});

export default MyApp;
