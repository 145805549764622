import { createSlice } from "@reduxjs/toolkit";

export const mainSlice = createSlice({
  name: "main",
  initialState: {
    data: null,
    contacts: null,
  },
  reducers: {
    mainBlockData: (state, action) => {
      state.data = action.payload;
    },
    contactUsData: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

export const { mainBlockData, contactUsData } = mainSlice.actions;

export default mainSlice.reducer;
