import { createSlice } from "@reduxjs/toolkit";
import { setCookie } from "../helpers/cookie.helpers";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    locale: -1,
    basketCount: 0,
    basket: [],
    productsCount: {},
    country: -1,
  },
  reducers: {
    changeCountry: (state, action) => {
      state.country = action.payload;
    },
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
    changeBasketCount: (state, action) => {
      state.basketCount = action.payload;
    },
    initBasket: (state, action) => {
      if (action.payload) {
        try {
          state.basket = JSON.parse(action.payload);
        } catch (e) {
          console.error("initBasket Error", e);
        }
      }
    },
    initProductsCount: (state, action) => {
      if (action.payload) {
        try {
          state.productsCount = JSON.parse(action.payload);
        } catch (e) {
          console.error("initProductsCount Error", e);
        }
      }
    },
    changeCountForProduct: (state, action) => {
      const countObj = { ...state.productsCount };
      countObj[action.payload.productId] = action.payload.count;

      setCookie("productsCount", JSON.stringify(countObj));
      state.productsCount = countObj;
    },
    addBasket: (state, action) => {
      let basket = [...state.basket];
      basket.push(action.payload);
      state.basket = basket;
      setCookie("basket", JSON.stringify(basket));
    },
    addRemoveFromBasket: (state, action) => {
      let basket = [...state.basket];

      const indexOfItem = basket.indexOf(action.payload);

      if (indexOfItem !== -1) {
        const countObj = { ...state.productsCount };

        basket = basket.splice(
          indexOfItem,
          basket?.length === 1 ? 0 : basket.length - 1
        );

        delete countObj[action.payload];
        setCookie("productsCount", JSON.stringify(countObj));
      } else {
        basket.push(action.payload);
      }

      setCookie("basket", JSON.stringify(basket));

      state.basket = basket;
    },
  },
});

export const {
  changeCountry,
  changeLocale,
  changeBasketCount,
  initBasket,
  addBasket,
  addRemoveFromBasket,
  initProductsCount,
  changeCountForProduct,
} = globalSlice.actions;

export default globalSlice.reducer;
