import axios from "axios";

// const baseUrl = "http://51.178.37.181/api/";
const baseUrl = "https://handle-brand.com/api";
const newBaseUrl = "https://dev.handle.am/api";
const baseURLIdram = "https://banking.idram.am/Payment/";

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
});

export const axiosInstanceNew = axios.create({
  baseURL: newBaseUrl,
  timeout: 60000,
});

export const axiosInstanceIdram = axios.create({
  baseURL: baseURLIdram,
  timeout: 60000,
});
const successResponse = (response) => {
  return response;
};

const errorResponse = (error) => {
  return Promise.reject(error);
};

axiosInstanceNew.interceptors.response.use(successResponse, errorResponse);
axiosInstance.interceptors.response.use(successResponse, errorResponse);
axiosInstanceIdram.interceptors.response.use(successResponse, errorResponse);
