export const getCookie = (name) => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

export const setCookie = (name, value, days) => {
  const d = new Date();

  if (days < 0) {
    d.setDate(d.getDate() - 1);
  } else {
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  }

  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
};

export const deleteCookie = (name) => {
  setCookie(name, "", -1);
};
