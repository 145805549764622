import React, { useState, useEffect } from "react";
import FacebookIcon from "../../assets/images/icons/facebook-white-icon.png";
import InstagramIcon from "../../assets/images/icons/instagram-white-icon.png";
import ViberIcon from "../../assets/images/icons/viber.png";
import WhatsappIcon from "../../assets/images/icons/whatsapp.png";
import TelegramIcon from "../../assets/images/icons/telegram.png";

const Chat = () => {
  const [open, setOpen] = useState(false);
  const [hostName, setHostName] = useState(null);

  const openChat = () => {
    setOpen(!open);
  };
  // window.open("https://m.me/handlebrandarmenia", "_blank"); messenger
  // window.open(" https://wa.me/79965377770", "_blank"); whatsapp
  // window.open("viber://chat/?number=%2B995595585866", "_blank");
  // window.open("https://www.instagram.com/direct/t/115706569813272", "_blank");
  const windowHost = async () => {
    const data = await window.location.hostname;
    setHostName(data);
  };

  useEffect(() => {
    windowHost();
  }, []);
  const openMessenger = () => {
    if (hostName === "handle.ge") {
      window.open("https://m.me/handlegeorgian", "_blank");
    } else {
      window.open("https://m.me/handlebrandarmenia", "_blank");
    }
  };
  const openWhatsapp = () => {
    if (hostName === "handle.ge") {
      window.open("https://wa.me/995595585866", "_blank");
    } else {
      window.open("https://wa.me/37433585850", "_blank");
    }
  };
  const openViber = () => {
    if (hostName === "handle.ge") {
      window.open("viber://chat/?number=%2B995595585866", "_blank");
    } else {
      window.open("viber://chat/?number=%2B37433585850", "_blank");
    }
  };

  const openInstagram = () => {
    if (hostName === "handle.ge") {
      window.open(
        "https://www.instagram.com/handle_georgia/?igshid=YmMyMTA2M2Y%3D",
        "_blank"
      );
    } else {
      window.open("https://www.instagram.com/handlebrand_armenia/", "_blank");
    }
  };
  const openTelegram = () => {
    if (hostName === "handle.ge") {
      window.open("https://telegram.me/handlebrandgeorgia", "_blank");
    } else {
      window.open("https://telegram.me/handlebrandarmenia", "_blank");
    }
  };

  //https://www.instagram.com/direct/t/115706569813272"
  return (
    <div
      onClick={openChat}
      className="chat-block"
    >
      <span className="chat-block-text">Chat with us</span>
      {open && (
        <div className="chat-icons">
          <div
            onClick={openMessenger}
            className="cursor"
          >
            <img
              src={FacebookIcon.src}
              alt=""
            />
            <span>Messenger</span>
          </div>
          <div
            onClick={openInstagram}
            className="cursor"
          >
            <img
              src={InstagramIcon.src}
              alt=""
            />
            <span>Instagram</span>
          </div>
          <div
            onClick={openTelegram}
            className="cursor"
          >
            <img
              src={TelegramIcon.src}
              alt=""
            />
            <span>Telegram</span>
          </div>
          <div
            onClick={openWhatsapp}
            className="cursor"
          >
            <img
              src={WhatsappIcon.src}
              alt=""
            />
            <span>Whatsapp</span>
          </div>
          <div
            onClick={openViber}
            className="cursor"
          >
            <img
              src={ViberIcon.src}
              alt=""
            />
            <span>Viber</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
