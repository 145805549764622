const localeEnum = {
  EN: 0,
  AM: 1,
  RU: 2,
  GE: 3,
};

Object.freeze(localeEnum);

export default localeEnum;
