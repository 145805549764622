import { configureStore } from "@reduxjs/toolkit";

import globalReducer from "./global.slice";
import mainSlice from "./main.slice";

export default configureStore({
  reducer: {
    global: globalReducer,
    main: mainSlice,
  },
});
