import { axiosInstance, axiosInstanceIdram, axiosInstanceNew } from "./config";

export const getProducts_req = async (page) => {
  const lang = await localStorage.getItem("lang");
  const response = await axiosInstance.get(
    `/products?page=${page ? page : 1}&limit=${12}&lang=${
      lang === null ? 0 : lang == -1 ? 0 : lang
    }`
  );
  return response.data;
};
export const getProductsItem_req = async (id) => {
  const lang = await localStorage.getItem("lang");
  const response = await axiosInstance.get(
    `/products/product/?id=${id}&lang=${
      lang === null ? 0 : lang == -1 ? 0 : lang
    }`
  );
  return response.data;
};

export const configsMain_req = async () => {
  const lang = await localStorage.getItem("lang");
  const response = await axiosInstance.get(
    `/configs/main-page/?lang=${lang === null ? 0 : lang == -1 ? 0 : lang}`
  );
  return response.data;
};

export const getCategories_req = async () => {
  const lang = await localStorage.getItem("lang");
  const response = await axiosInstance.get(
    `/categories/pinned-groups?lang=${
      lang === null ? 0 : lang == -1 ? 0 : lang
    }`
  );
  return response.data;
};

export const getAboutUs_req = async (lang) => {
  const response = await axiosInstance.get(
    `/configs/about-us?&lang=${lang === null ? 0 : lang == -1 ? 0 : lang}`
  );
  return response.data;
};

export const contacts_req = async (state) => {
  const name = state.name;
  const email = state.email;
  const description = state.description;
  const phone = state.phone;
  const response = await axiosInstance.post("configs/add-new-message", {
    name,
    email,
    description,
    phone,
  });
  return response.data;
};
export const basket_req = async (productIds) => {
  const lang = await localStorage.getItem("lang");
  const response = await axiosInstance.post("products/ids", {
    productIds,
    lang: lang === null ? 0 : lang == -1 ? 0 : Number(lang),
  });
  return response.data;
};
export const search_req = async (title, page) => {
  const lang = await localStorage.getItem("lang");
  const response = await axiosInstance.get(
    `products/search?text=${title}&page=${page}&limit=12&lang=${
      lang === null ? 0 : lang == -1 ? 0 : lang
    }`
  );
  return response.data;
};
export const categoryGet_req = async () => {
  const lang = await localStorage.getItem("lang");

  const response = axiosInstance.get(
    `/categories?lang=${lang === null ? 0 : lang == -1 ? 0 : lang}`
  );
  return response;
};
export const sizeGet_req = async () => {
  const response = axiosInstance.get("/categories/sizes");
  return response;
};

export const arca_req = async (data) => {
  const orderId = data.orderId;
  const response = axiosInstance.post("/payment/arca/validate", {
    orderId,
  });
  return response;
};
export const buyNowArca_req = async (productIds, state, totalPrice) => {
  const lang = await localStorage.getItem("lang");
  const email = state.email;
  const city = state.city;
  const country = state.country;
  const address = state.address;
  const zipCode = state.zipCode;
  const phone = state.phone;
  const platform = "web";
  const response = axiosInstance.post("/payment/arca/data", {
    productIds,
    email,
    city,
    country,
    address,
    zipCode,
    phone,
    lang: lang == -1 ? 0 : lang,
    platform,
    totalPrice,
  });
  return response;
};
export const buyNow_req = async (productIds, state, totalPrice) => {
  const lang = await localStorage.getItem("lang");
  const email = state.email;
  const city = state.city;
  const country = state.country;
  const address = state.address;
  const zipCode = state.zipCode;
  const phone = state.phone;

  const response = axiosInstance.post("/payment/idram/data", {
    productIds,
    email,
    city,
    country,
    address,
    zipCode,
    phone,
    totalPrice,
    lang: lang == -1 ? 0 : lang,
  });
  return response;
};
export const idram_req = async () => {
  const response = axiosInstanceIdram.post("GetPayment", {
    EDP_LANGUAGE: "EN",
    EDP_REC_ACCOUNT: "110001079",
    EDP_DESCRIPTION: "Order",
    EDP_AMOUNT: "1900",
    EDP_BILL_NO: "1806",
  });
  return response;
};

export const filterProducts_req = async (
  categoryIds,
  sizeId,
  price,
  pageData,
  discount
) => {
  const lang = await localStorage.getItem("lang");
  const limit = 12;
  const page = Number(pageData);
  const minPrice = Number(price?.minPrice);
  const maxPrice =
    price?.maxPrice?.length > 0 ? Number(price?.maxPrice) : 99999999;

  // localStorage.setItem("min", minPrice > 0 ? minPrice : "");
  // localStorage.setItem("max", Number(price?.maxPrice) > 0 ? maxPrice : "");
  const response = axiosInstance.post(`/products/filter-by-categories`, {
    categoryIds,
    sizeId,
    minPrice,
    maxPrice,
    page,
    limit,
    lang: lang == -1 ? 0 : lang,
    discount: discount === 1 ? true : false,
  });
  return response;
};

export const getCountryLang_req = async () => {
  const response = axiosInstance.get("/configs/country");
  return response;
};

export const contactUs_req = async () => {
  const response = axiosInstance.get("/configs/contact-us");
  return response;
};

export const paymentsCache_req = async (productIds, state) => {
  const email = state.email;
  const city = state.city;
  const country = state.country;
  const address = state.address;
  const zipCode = state.zipCode;
  const phone = state.phone;
  const resposne = axiosInstance.post("/payment/cash", {
    productIds,
    email,
    city,
    country,
    address,
    zipCode,
    phone,
  });
  return resposne;
};

export const getPrivacy_req = async () => {
  const lang = await localStorage.getItem("lang");
  const response = axiosInstance.get(
    `/configs/privacy-policy?lang=${lang == -1 ? 0 : lang}`
  );
  return response;
};

export const getDiscountProduct_req = async (page) => {
  const lang = await localStorage.getItem("lang");
  const response = axiosInstance.get(
    `/products/discount?page=${page ? page : 1}&limit=12&lang=${
      lang == -1 ? 0 : lang
    }`
  );
  return response;
};

export const getFilterSize_req = async (categoriesId) => {
  const response = axiosInstance.post(`/categories/sizes`, {
    categoriesId,
  });
  return response;
};
export const getAllProduct_req = async (page) => {
  const lang = await localStorage.getItem("lang");
  const limit = 12;
  const response = axiosInstance.get(
    `/products?page=${page}&limit=${limit}&lang=${
      lang === null ? 0 : lang == -1 ? 0 : lang
    }`
  );
  return response;
};

export const getFeautered_req = async () => {
  const lang = await localStorage.getItem("lang");
  const response = axiosInstance.get(
    `/products/featured-products/?lang=${
      lang === null ? 0 : lang == -1 ? 0 : lang
    }`
  );
  return response;
};
export const getProductAccessory_req = async (productId) => {
  const response = axiosInstance.get(
    `/products/accessories/?productId=${productId}`
  );
  return response;
};

// export const getFeatured_req = async () => {
//   const lang = await localStorage.getItem("lang");

//   const response = axiosInstance.get(
//     `/products/featured-products/?lang=${
//       lang === null ? 0 : lang == -1 ? 0 : lang
//     }`
//   );
//   return response;
// };

export const getBlogs_req = async () => {
  const lang = await localStorage.getItem("lang");
  const response = axiosInstance.get(
    `/blog/posts?lang=${
      lang === null ? 0 : lang == -1 ? 0 : lang
    }&limit=10&page=1`
  );
  return response;
};
export const getBlogInner_req = async (id) => {
  const lang = await localStorage.getItem("lang");
  const response = axiosInstance.get(
    `/blog?postId=${id}&lang=${lang === null ? 0 : lang == -1 ? 0 : lang}`
  );
  return response;
};
