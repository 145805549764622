import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import mauWin from "../../assets/chess.json";
const Loader = () => {
  return (
    <div className="loader">
      <Player src={mauWin} autoplay loop />
    </div>
  );
};
export default Loader;
