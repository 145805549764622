import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/logo.svg";
import FlagArm from "../../../assets/images/armenia.png";
import FlagRus from "../../../assets/images/russia.png";
import FlagUS from "../../../assets/images/united-states.png";
import FlagGe from "../../../assets/images/georgia.png";
import IdramLogo from "../../../assets/images/idram_logo.png";
import SearchIcon from "../../../assets/images/search-icon.png";
import CloseIcon from "../../../assets/images/close.png";
import LocationWhiteIcon from "../../../assets/images/icons/location-white-icon.png";
import EnvelopWhiteIcon from "../../../assets/images/icons/envelop-white-icon.png";
import PhoneWhiteIcon from "../../../assets/images/icons/phone-white-icon.png";
import FacebookIcon from "../../../assets/images/icons/facebook-white-icon.png";
import Etsy from "../../../assets/images/icons/etsy.png";
import TikTok from "../../../assets/images/icons/tik-tok.png";
import InstagramIcon from "../../../assets/images/icons/instagram-white-icon.png";
import ViberIcon from "../../../assets/images/icons/viber.png";
import WhatsappIcon from "../../../assets/images/icons/whatsapp.png";
import TelegramIcon from "../../../assets/images/icons/telegram.png";
import BasketIcon from "../../../assets/images/icons/basket-white-icon.png";
import DownArrowIcon from "../../../assets/images/icons/down-arrow.png";
import VisaIcon from "../../../assets/images/icons/visa.png";
import MasterCardIcon from "../../../assets/images/icons/master.png";
import AmericanExpressIcon from "../../../assets/images/icons/american-express.png";
import MenuIcon from "../../../assets/images/icons/menu.png";
import CloseWhiteIcon from "../../../assets/images/icons/close.png";
import ChessHeadImage1 from "../../../assets/images/header-images/chess-1.jpg";
import ChessHeadImage2 from "../../../assets/images/header-images/chess-2.jpg";
import ChessHeadImage3 from "../../../assets/images/header-images/chess-3.jpg";
import ChessHeadImage4 from "../../../assets/images/header-images/chess-4.jpg";
import ChessHeadImage5 from "../../../assets/images/header-images/chess-5.jpg";
import ChessHeadImage6 from "../../../assets/images/header-images/chess-6.jpg";
import BackgammonHeadImage1 from "../../../assets/images/header-images/backgammon-1.jpg";
import BackgammonHeadImage2 from "../../../assets/images/header-images/backgammon-2.jpg";
import BackgammonHeadImage3 from "../../../assets/images/header-images/backgammon-3.jpg";
import { contactUs_req, getCategories_req } from "../../../api/api";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../helpers/cookie.helpers";
import {
  changeLocale,
  initBasket,
  initProductsCount,
} from "../../../redux/global.slice";
import { contactUsData } from "../../../redux/main.slice";
import { useIntl } from "react-intl";
import Link from "next/link";

export default function MainLayout({ children }) {
  const dispatch = useDispatch();
  const router = useRouter();

  const intl = useIntl();
  const [categoriesState, setCategoriesState] = useState(null);
  const [openSearch, setOpenSearch] = useState(true);
  const [contactsData, setContactsData] = useState(null);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [languagesList, setLanguagesList] = useState(false);
  const [langText, setLangText] = useState(null);
  const [langIcon, setLangIcon] = useState(null);
  const [hostName, setHostName] = useState(null);
  const countBasket = useSelector((state) => state.global.basketCount);
  const country = useSelector((state) => state.main.data?.country);

  const countryCode = String(country);
  const [search, setSearch] = useState("");
  const handleChange = (evt) => {
    const value = evt?.target?.value;

    setSearch(value);
  };

  const [headSearch, setHeadSearch] = useState(false);

  const [isShownChess, setIsShownChess] = useState(null);
  const [isShownBackgammon, setIsShownBackgammon] = useState(null);

  const basketCount = useSelector((state) => state.global.basket);

  const locale = useSelector((state) => state.global.locale);
  const basketCountData = async () => {
    const data = await getCookie("basket");
  };
  const windowHost = async () => {
    const data = await window.location.hostname;
    setHostName(data);
  };

  useEffect(() => {
    basketCountData();
    windowHost();
  }, []);
  const handleLanguagesList = () => {
    setLanguagesList(!languagesList);
  };
  const handleBurgerMenu = () => {
    setBurgerMenu(!burgerMenu);
  };

  useEffect(() => {
    if (router?.route === "/all/[id]") {
      setOpenSearch(false);
      setHeadSearch(false);
    } else {
      setOpenSearch(true);
      setHeadSearch(false);
    }
    setBurgerMenu(false);
  }, [router?.route]);

  const langData = [
    { id: 0, text: "EN", icon: FlagUS.src },
    { id: 1, text: "AM", icon: FlagArm.src },
    { id: 2, text: "RU", icon: FlagRus.src },
    { id: 3, text: "GE", icon: FlagGe.src },
  ];

  const categoriesData = async () => {
    const categories = await getCategories_req();
    setCategoriesState(categories);
  };

  const contactUsReq = async () => {
    const contactsDataReq = await contactUs_req();
    if (contactsDataReq.data) {
      dispatch(contactUsData(contactsDataReq.data));
      setContactsData(contactsDataReq.data);
    }
  };
  const goBasket = () => {
    router.push("/basket");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      router.push({
        pathname: `/all/1`,
        query: { search },
      });
    }
  };

  const funcData = async () => {
    const langLocale = await localStorage.getItem("lang");
    if (langLocale) {
      if (langLocale === 0) {
        return setLangIcon(FlagUS.src), setLangText("EN");
      } else if (langLocale == 1) {
        return setLangIcon(FlagArm.src), setLangText("AM");
      } else if (langLocale == 2) {
        return setLangIcon(FlagRus.src), setLangText("RU");
      } else if (langLocale == 3) {
        return setLangIcon(FlagGe.src), setLangText("GE");
      } else {
        return setLangIcon(FlagUS.src), setLangText("EN");
      }
    } else {
      return setLangIcon(FlagUS.src), setLangText("EN");
    }
  };

  useEffect(() => {
    contactUsReq();

    funcData();
  }, []);
  useEffect(() => {
    categoriesData();
  }, [locale]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(initBasket(getCookie("basket")));
      dispatch(initProductsCount(getCookie("productsCount")));
    }, 1000);
  }, [countBasket]);

  const goAllProduct = () => {
    router.push(`/all/1?search=${search}`, `/all/1`);
  };

  const changeLang = (langLocale) => {
    localStorage.setItem("lang", langLocale);
    dispatch(changeLocale(langLocale));
    setLanguagesList(!languagesList);
    if (langLocale === 0) {
      return setLangIcon(FlagUS.src), setLangText("EN");
    } else if (langLocale == 1) {
      return setLangIcon(FlagArm.src), setLangText("AM");
    } else if (langLocale == 2) {
      return setLangIcon(FlagRus.src), setLangText("RU");
    } else if (langLocale == 3) {
      return setLangIcon(FlagGe.src), setLangText("GE");
    } else {
      return setLangIcon(FlagUS.src), setLangText("EN");
    }
  };

  const chessData = categoriesState?.filter((item) => item.name === "ՇԱԽՄԱՏ");
  const chessDataParse = chessData
    ? JSON.parse(chessData?.[0]?.categories)
    : null;

  const backgammon = categoriesState?.filter((item) => item.name === "ՆԱՐԴԻ");
  const backgammonParse = backgammon
    ? JSON.parse(backgammon?.[0]?.categories)
    : null;
  const handleHeadSearch = () => {
    setHeadSearch(!headSearch);
  };

  const goHome = () => {
    router.push(`/`);
  };

  const checkNavActiveChess = () => {
    switch (isShownChess) {
      case 0:
        return (
          <img
            src={ChessHeadImage1.src}
            alt=""
          />
        );
      case 1:
        return (
          <img
            src={ChessHeadImage2.src}
            alt=""
          />
        );
      case 2:
        return (
          <img
            src={ChessHeadImage3.src}
            alt=""
          />
        );
      case 3:
        return (
          <img
            src={ChessHeadImage4.src}
            alt=""
          />
        );
      case 4:
        return (
          <img
            src={ChessHeadImage5.src}
            alt=""
          />
        );
      case 5:
        return (
          <img
            src={ChessHeadImage6.src}
            alt=""
          />
        );
      default:
        return "";
    }
  };

  const checkNavActiveBackgammon = () => {
    switch (isShownBackgammon) {
      case 0:
        return (
          <img
            src={BackgammonHeadImage1.src}
            alt=""
          />
        );
      case 1:
        return (
          <img
            src={BackgammonHeadImage2.src}
            alt=""
          />
        );
      case 2:
        return (
          <img
            src={BackgammonHeadImage3.src}
            alt=""
          />
        );
      default:
        return "";
    }
  };

  return (
    <>
      <main
        role="document"
        tabIndex={0}
        className={`${burgerMenu ? "hidden" : ""}`}
        style={{ fontFamily: locale === 1 ? "Montserrat arm" : "Montserrat" }}
      >
        <header>
          <div className="wrapper">
            <div className="head flex flex-jc-between flex-ai-center">
              <div className="head-left flex flex-ai-center">
                <button
                  className="logo"
                  onClick={goHome}
                >
                  <img
                    src={Logo.src}
                    alt="Handle Logo"
                  />
                </button>
                <nav className={`${burgerMenu ? "open" : ""}`}>
                  <ul className="navbar flex">
                    <li className="navbar-item">
                      <Link href={"/shop/1?&item=5"}>
                        {intl.messages.chess}
                      </Link>
                      <div className="submenu-box">
                        <ul className="submenu">
                          <>
                            {chessDataParse?.map((item, i) => {
                              return (
                                <li
                                  key={i}
                                  onMouseEnter={() => setIsShownChess(i)}
                                >
                                  <Link href={`/shop/1?&item=${item.id}`}>
                                    {item.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </>
                        </ul>
                        {checkNavActiveChess()}
                      </div>
                    </li>
                    <li className="navbar-item">
                      <Link href={"/shop/1?&item=6"}>
                        {intl.messages.backgammon}
                      </Link>
                      <div className="submenu-box">
                        <ul className="submenu">
                          {backgammonParse?.map((item, i) => (
                            <li
                              key={i}
                              onMouseEnter={() => setIsShownBackgammon(i)}
                            >
                              <Link href={`/shop/1?&item=${item.id}`}>
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        {checkNavActiveBackgammon()}
                      </div>
                    </li>
                    <li className="navbar-item">
                      <Link href={"/all/1"}>{intl.messages.wholeRange}</Link>
                    </li>
                    <li className="navbar-item">
                      <Link href={"/discount/1"}>
                        {intl.messages.discountedProducts}
                      </Link>
                    </li>
                    <li className="navbar-item">
                      <Link href={`/blog`}>{intl.messages.blog}</Link>
                      {/* <div className="submenu-box">
                        <ul className="submenu">
                          <li>
                            <Link href={`/shop/1?&item=55`}>
                              {intl.messages.individual_chess}
                            </Link>
                          </li>
                          <li>
                            <Link href={`/shop/1?&item=56`}>
                              {intl.messages.individual_backgammons}
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </li>
                    <li className="navbar-item">
                      <Link href={"/about"}>{intl.messages.aboutUs}</Link>
                    </li>
                    <li className="navbar-item">
                      <Link href={"/contact"}>{intl.messages.contactUs}</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="head-right">
                <div className="flex flex-ai-center">
                  <div
                    className="head-basket"
                    onClick={goBasket}
                  >
                    <div className="head-basket-btn">
                      {basketCount ? (
                        <span className="count">{basketCount.length}</span>
                      ) : (
                        ""
                      )}
                      <img
                        src={BasketIcon.src}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className={`language ${languagesList ? "open" : ""}`}>
                    <img
                      src={langIcon}
                      alt="#"
                    />
                    <span
                      className="selected-language"
                      onClick={handleLanguagesList}
                    >
                      {langText || "EN"}

                      <img
                        src={DownArrowIcon.src}
                        alt=""
                      />
                    </span>
                    {languagesList && (
                      <ul className="language-list">
                        {langData.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => changeLang(item.id)}
                          >
                            <img
                              src={item.icon}
                              alt={item.text}
                            />
                            {item.text}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <button
                    className="head-search-button"
                    onClick={handleHeadSearch}
                  >
                    {openSearch && (
                      <>
                        {headSearch ? (
                          <img
                            src={CloseIcon.src}
                            alt=""
                          />
                        ) : (
                          <img
                            src={SearchIcon.src}
                            alt=""
                          />
                        )}
                      </>
                    )}
                  </button>
                  <div className="mobile-menu-burger">
                    <button onClick={handleBurgerMenu}>
                      {burgerMenu ? (
                        <img
                          src={CloseWhiteIcon.src}
                          alt=""
                        />
                      ) : (
                        <img
                          src={MenuIcon.src}
                          alt=""
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {headSearch && openSearch && (
            <div className="head-search">
              <div className="wrapper">
                <div>
                  <input
                    type="text"
                    placeholder={intl.messages.search}
                    onChange={handleChange}
                    value={search}
                    name="search"
                    onKeyDown={handleKeyDown}
                  />
                  <Link
                    href={{
                      pathname: `/all/1`,
                      query: { search },
                    }}
                  >
                    {intl.messages.search}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </header>
        {children}
        <footer>
          {/*<Subscribe />*/}
          <div className="foo">
            <div className="wrapper">
              <div className="foo-top flex">
                <div className="foo-info">
                  <button
                    className="foo-logo"
                    onClick={goHome}
                  >
                    <img
                      src={Logo.src}
                      alt="Handle Logo"
                    />
                  </button>
                  {/* {hostName !== "handle.ge" && (
                    <div className="foo-address">
                      <a
                        href="https://www.google.com/maps/place/Handle+Brand/@40.1748637,44.5192301,21z/data=!4m5!3m4!1s0x406abcf14bc470ad:0xcc54b06955236c6e!8m2!3d40.1748391!4d44.5192709"
                        className="flex flex-ai-center"
                        target="_blank"
                      >
                        <img src={LocationWhiteIcon.src} alt="" />
                        {contactsData?.address}
                      </a>
                    </div>
                  )} */}
                  {hostName === "handle.ge" && (
                    <>
                      {countryCode === "1" && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/Handle+Brand/@40.1748637,44.5192301,21z/data=!4m5!3m4!1s0x406abcf14bc470ad:0xcc54b06955236c6e!8m2!3d40.1748391!4d44.5192709"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            {contactsData?.address}
                          </a>
                        </div>
                      )}
                      {countryCode === "2" && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/ул.+Селезнева,+147,+Краснодар,+Краснодарский+край,+Россия,+350075/@45.0258247,39.0325156,17z/data=!3m1!4b1!4m6!3m5!1s0x40f050015c747341:0x5bd3a74027bd9ef2!8m2!3d45.0258247!4d39.0350905!16s%2Fg%2F11csfngyf8"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            Г. Краснодар ул. Селезнева 147
                          </a>
                        </div>
                      )}
                      {(countryCode === "3" || countryCode === "0") && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/2+Javakheti+I+Turn,+T'bilisi,+%D0%93%D1%80%D1%83%D0%B7%D0%B8%D1%8F/@41.6920725,44.8675198,17z/data=!3m1!4b1!4m6!3m5!1s0x40440dc0424b16c5:0xd03d01db0352f799!8m2!3d41.6920725!4d44.8700947!16s%2Fg%2F11c3q22np1"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            2 Javakheti turn, 19 building 2
                          </a>
                        </div>
                      )}
                    </>
                  )}
                  {hostName === "handle-brand.ru" && (
                    <>
                      {countryCode === "1" && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/Handle+Brand/@40.1748637,44.5192301,21z/data=!4m5!3m4!1s0x406abcf14bc470ad:0xcc54b06955236c6e!8m2!3d40.1748391!4d44.5192709"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            {contactsData?.address}
                          </a>
                        </div>
                      )}
                      {(countryCode === "2" || countryCode === "0") && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/ул.+Селезнева,+147,+Краснодар,+Краснодарский+край,+Россия,+350075/@45.0258247,39.0325156,17z/data=!3m1!4b1!4m6!3m5!1s0x40f050015c747341:0x5bd3a74027bd9ef2!8m2!3d45.0258247!4d39.0350905!16s%2Fg%2F11csfngyf8"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            Г. Краснодар ул. Селезнева 147
                          </a>
                        </div>
                      )}
                      {countryCode === "3" && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/2+Javakheti+I+Turn,+T'bilisi,+%D0%93%D1%80%D1%83%D0%B7%D0%B8%D1%8F/@41.6920725,44.8675198,17z/data=!3m1!4b1!4m6!3m5!1s0x40440dc0424b16c5:0xd03d01db0352f799!8m2!3d41.6920725!4d44.8700947!16s%2Fg%2F11c3q22np1"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            2 Javakheti turn, 19 building 2
                          </a>
                        </div>
                      )}
                    </>
                  )}
                  {(hostName === "handle-brand.com" ||
                    hostName === "handle.am") && (
                    <>
                      {(countryCode === "1" || countryCode === "0") && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/Handle+Brand/@40.1748637,44.5192301,21z/data=!4m5!3m4!1s0x406abcf14bc470ad:0xcc54b06955236c6e!8m2!3d40.1748391!4d44.5192709"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            {contactsData?.address}
                          </a>
                        </div>
                      )}
                      {countryCode === "2" && (
                        <div className="foo-address">
                          <a
                            href="https://google.com/maps/place/ул.+Селезнева,+147,+Краснодар,+Краснодарский+край,+Россия,+350075/@45.0258247,39.0325156,17z/data=!3m1!4b1!4m6!3m5!1s0x40f050015c747341:0x5bd3a74027bd9ef2!8m2!3d45.0258247!4d39.0350905!16s%2Fg%2F11csfngyf8"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            Г. Краснодар ул. Селезнева 147
                          </a>
                        </div>
                      )}
                      {countryCode === "3" && (
                        <div className="foo-address">
                          <a
                            href="https://www.google.com/maps/place/2+Javakheti+I+Turn,+T'bilisi,+%D0%93%D1%80%D1%83%D0%B7%D0%B8%D1%8F/@41.6920725,44.8675198,17z/data=!3m1!4b1!4m6!3m5!1s0x40440dc0424b16c5:0xd03d01db0352f799!8m2!3d41.6920725!4d44.8700947!16s%2Fg%2F11c3q22np1"
                            className="flex flex-ai-center"
                            target="_blank"
                          >
                            <img
                              src={LocationWhiteIcon.src}
                              alt=""
                            />
                            2 Javakheti turn, 19 building 2
                          </a>
                        </div>
                      )}
                    </>
                  )}

                  <div className="foo-email flex flex-ai-center">
                    <img
                      src={EnvelopWhiteIcon.src}
                      alt=""
                    />
                    {hostName === "handle.ge" && (
                      <>
                        {countryCode === "1" && (
                          <a href={`mailto:handlebrandarmenia@gmail.com`}>
                            handlebrandarmenia@gmail.com
                          </a>
                        )}
                        {countryCode === "2" && (
                          <a href="mailto:handlebrand@mail.ru">
                            handlebrand@mail.ru
                          </a>
                        )}
                        {(countryCode === "3" || countryCode === "0") && (
                          <a href="mailto:handlebrandgeorgia@gmail.com">
                            handlebrandgeorgia@gmail.com
                          </a>
                        )}
                      </>
                    )}
                    {(hostName === "handle.am" ||
                      hostName === "handle-brand.com") && (
                      <>
                        {(countryCode === "1" || countryCode === "0") && (
                          <a href={`mailto:handlebrandarmenia@gmail.com`}>
                            handlebrandarmenia@gmail.com
                          </a>
                        )}
                        {countryCode === "2" && (
                          <a href="mailto:handlebrand@mail.ru">
                            handlebrand@mail.ru
                          </a>
                        )}
                        {countryCode === "3" && (
                          <a href="mailto:handlebrandgeorgia@gmail.com">
                            handlebrandgeorgia@gmail.com
                          </a>
                        )}
                      </>
                    )}
                    {hostName === "handle-brand.ru" && (
                      <>
                        {countryCode === "1" && (
                          <a href="mailto:handlebrandarmenia@gmail.com">
                            handlebrandarmenia@gmail.com
                          </a>
                        )}
                        {(countryCode === "2" || countryCode === "0") && (
                          <a href="mailto:handlebrand@mail.ru">
                            handlebrand@mail.ru
                          </a>
                        )}
                        {countryCode === "3" && (
                          <a href="mailto:handlebrandgeorgia@gmail.com">
                            handlebrandgeorgia@gmail.com
                          </a>
                        )}
                      </>
                    )}
                  </div>
                  {/* { hostName === "handle.ge" ? (
                      <a href="mailto:handlebrandgeorgia@gmail.com">
                        handlebrandgeorgia@gmail.com
                      </a>
                    ) : (
                      <a href={`mailto:${contactUsData?.email}`}>
                        {contactsData?.email}
                      </a>
                    )}
                  </div>
                  Handlebrand@mail.ru */}

                  <div className="foo-phone flex flex-ai-center">
                    <img
                      src={PhoneWhiteIcon.src}
                      alt=""
                    />

                    {hostName === "handle.ge" && (
                      <>
                        {countryCode === "1" && (
                          <a href="tel:+37433585850">{contactsData?.phone1}</a>
                        )}
                        {countryCode === "2" && (
                          <a href="tel:+79965377770">+79965377770</a>
                        )}
                        {(countryCode === "3" || countryCode === "0") && (
                          <a href="tel:+995595585866">+995595585866</a>
                        )}
                      </>
                    )}
                    {hostName === "handle-brand.ru" && (
                      <>
                        {countryCode === "1" && (
                          <a href="tel:+37433585850">{contactsData?.phone1}</a>
                        )}
                        {(countryCode === "2" || countryCode === "0") && (
                          <a href="tel:+79965377770">+79965377770</a>
                        )}
                        {countryCode === "3" && (
                          <a href="tel:+995595585866">+995595585866</a>
                        )}
                      </>
                    )}
                    {(hostName === "handle-brand.com" ||
                      hostName === "handle.am") && (
                      <>
                        {(countryCode === "1" || countryCode === "0") && (
                          <a href="tel:+37433585850">{contactsData?.phone1}</a>
                        )}
                        {countryCode === "2" && (
                          <a href="tel:+79965377770">+79965377770</a>
                        )}
                        {countryCode === "3" && (
                          <a href="tel:+995595585866">+995595585866</a>
                        )}
                      </>
                    )}
                    {/* {countryCode === "3" && (
                      <a href="tel:995595585866">+995595585866</a>
                    )}

                    {countryCode === "2" && (
                      <a href="tel:+79965377770">+79965377770</a>
                    )}
                    {countryCode === "1" && (
                      <a href="tel:37433585850">{contactsData?.phone1}</a>
                    )}
                    {hostName === "handle.ge" && countryCode === "0" && (
                      <a href="tel:995595585866">+995595585866</a>
                    )}
                    {hostName === "handle-brand.ru" && countryCode === "0" && (
                      <a href="tel:79584654497">+79584654497</a>
                    )}
                    {(hostName === "handle.am" ||
                      hostName === "handle-brand.com") &&
                      countryCode === "0" && (
                        <a href="tel:37433585850">{contactsData?.phone1}</a>
                      )} */}
                    <span className="flex">
                      {hostName === "handle.ge" && (
                        <>
                          {countryCode === "1" && (
                            <>
                              <a
                                href="https://wa.me/37433585850"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B37433585850"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/handlebrandarmenia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                          {countryCode === "2" && (
                            <>
                              <a
                                href="https://wa.me/79965377770"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B79965377770"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/Handlerussia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                          {(countryCode === "3" || countryCode === "0") && (
                            <>
                              <a
                                href="https://wa.me/995595585866"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B995595585866"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/handlebrandgeorgia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                        </>
                      )}

                      {(hostName === "handle.am" ||
                        hostName === "handle-brand.com") && (
                        <>
                          {(countryCode === "1" || countryCode === "0") && (
                            <>
                              <a
                                href="https://wa.me/37433585850"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B37433585850"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/handlebrandarmenia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                          {countryCode === "2" && (
                            <>
                              <a
                                href="https://wa.me/79965377770"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B79965377770"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/Handlerussia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                          {countryCode === "3" && (
                            <>
                              <a
                                href="https://wa.me/995595585866"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B995595585866"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/handlebrandgeorgia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                        </>
                      )}
                      {hostName === "handle-brand.ru" && (
                        <>
                          {countryCode === "1" && (
                            <>
                              <a
                                href="https://wa.me/37433585850"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B37433585850"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/handlebrandarmenia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                          {(countryCode === "2" || countryCode === "0") && (
                            <>
                              <a
                                href="https://wa.me/79965377770"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B79965377770"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/Handlerussia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                          {countryCode === "3" && (
                            <>
                              <a
                                href="https://wa.me/995595585866"
                                target="_blank"
                              >
                                <img
                                  src={WhatsappIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="viber://chat/?number=%2B995595585866"
                                target="_blank"
                              >
                                <img
                                  src={ViberIcon.src}
                                  alt=""
                                />
                              </a>
                              <a
                                href="https://telegram.me/handlebrandgeorgia"
                                target="_blank"
                              >
                                <img
                                  src={TelegramIcon.src}
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                        </>
                      )}

                      {/* {countryCode === "3" && (
                        <>
                          <a href="https://wa.me/995593152339" target="_blank">
                            <img src={WhatsappIcon.src} alt="" />
                          </a>
                          <a
                            href="viber://chat/?number=%2B995593152339"
                            target="_blank"
                          >
                            <img src={ViberIcon.src} alt="" />
                          </a>
                          <a
                            href="https://telegram.me/handlebrandarmenia"
                            target="_blank"
                          >
                            <img src={TelegramIcon.src} alt="" />
                          </a>
                        </>
                      )}
                      {countryCode === "2" && (
                        <>
                          <a href="https://wa.me/79584654497" target="_blank">
                            <img src={WhatsappIcon.src} alt="" />
                          </a>
                          <a
                            href="viber://chat/?number=%2B79584654497"
                            target="_blank"
                          >
                            <img src={ViberIcon.src} alt="" />
                          </a>
                          <a
                            href="https://telegram.me/handlebrandarmenia"
                            target="_blank"
                          >
                            <img src={TelegramIcon.src} alt="" />
                          </a>
                        </>
                      )}
                      {countryCode === "1" && (
                        <>
                          <a href="https://wa.me/37433585850" target="_blank">
                            <img src={WhatsappIcon.src} alt="" />
                          </a>
                          <a
                            href="viber://chat/?number=%2B37433585850"
                            target="_blank"
                          >
                            <img src={ViberIcon.src} alt="" />
                          </a>
                          <a
                            href="https://telegram.me/handlebrandarmenia"
                            target="_blank"
                          >
                            <img src={TelegramIcon.src} alt="" />
                          </a>
                        </>
                      )}
                      {countryCode === "0" && hostName === "handle.ge" && (
                        <>
                          <a href="https://wa.me/995593152339" target="_blank">
                            <img src={WhatsappIcon.src} alt="" />
                          </a>
                          <a
                            href="viber://chat/?number=%2B995593152339"
                            target="_blank"
                          >
                            <img src={ViberIcon.src} alt="" />
                          </a>
                          <a
                            href="https://telegram.me/handlebrandarmenia"
                            target="_blank"
                          >
                            <img src={TelegramIcon.src} alt="" />
                          </a>
                        </>
                      )}
                      {countryCode === "0" && hostName === "handle-brand.ru" && (
                        <>
                          <a href="https://wa.me/79584654497" target="_blank">
                            <img src={WhatsappIcon.src} alt="" />
                          </a>
                          <a
                            href="viber://chat/?number=%2B79584654497"
                            target="_blank"
                          >
                            <img src={ViberIcon.src} alt="" />
                          </a>
                          <a
                            href="https://telegram.me/handlebrandarmenia"
                            target="_blank"
                          >
                            <img src={TelegramIcon.src} alt="" />
                          </a>
                        </>
                      )}
                      {(hostName === "handle-brand.com" ||
                        hostName === "handle.am") &&
                        countryCode === "0" && (
                          <>
                            <a href="https://wa.me/37433585850" target="_blank">
                              <img src={WhatsappIcon.src} alt="" />
                            </a>
                            <a
                              href="viber://chat/?number=%2B37433585850"
                              target="_blank"
                            >
                              <img src={ViberIcon.src} alt="" />
                            </a>
                            <a
                              href="https://telegram.me/handlebrandarmenia"
                              target="_blank"
                            >
                              <img src={TelegramIcon.src} alt="" />
                            </a>
                          </>
                        )} */}
                    </span>
                  </div>
                  {/* {hostName === "handle-brand.com" && (
                    <div className="foo-phone flex flex-ai-center">
                      <img src={PhoneWhiteIcon.src} alt="" />
                      <a href="tel:011585800">{contactsData?.phone2}</a>
                    </div>
                  )} */}
                  <div className="social-box flex flex-ai-center">
                    <span className="mr-20">{intl.messages.findUs}:</span>
                    <ul className="flex flex-ai-center">
                      {hostName === "handle.ge" && (
                        <>
                          {countryCode === "1" && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlebrandarmenia"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://instagram.com/handlebrand_armenia?igshid=YmMyMTA2M2Y="
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlebrand.co"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                          {countryCode === "2" && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlebrandarmenia"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://instagram.com/handlebrand_armenia?igshid=YmMyMTA2M2Y="
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlebrand.co"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                          {(countryCode === "3" || countryCode === "0") && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlegeorgian"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/handle_georgia/?igshid=YmMyMTA2M2Y%3D"
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlegeorgia"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                        </>
                      )}
                      {hostName === "handle-brand.ru" && (
                        <>
                          {countryCode === "1" && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlebrandarmenia"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://instagram.com/handlebrand_armenia?igshid=YmMyMTA2M2Y="
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlebrand.co"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                          {(countryCode === "2" || countryCode === "0") && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlebrandarmenia"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://instagram.com/handlebrand_armenia?igshid=YmMyMTA2M2Y="
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlebrand.co"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                          {countryCode === "3" && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlegeorgian"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/handle_georgia/?igshid=YmMyMTA2M2Y%3D"
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlegeorgia"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                        </>
                      )}
                      {(hostName === "handle-brand.com" ||
                        hostName === "handle.am") && (
                        <>
                          {(countryCode === "1" || countryCode === "0") && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlebrandarmenia"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://instagram.com/handlebrand_armenia?igshid=YmMyMTA2M2Y="
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlebrand.co"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                          {countryCode === "2" && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlebrandarmenia"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://instagram.com/handlebrand_armenia?igshid=YmMyMTA2M2Y="
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlebrand.co"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                          {countryCode === "3" && (
                            <>
                              <li>
                                <a
                                  href="https://www.facebook.com/handlegeorgian"
                                  target="_blank"
                                >
                                  <img
                                    src={FacebookIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/handle_georgia/?igshid=YmMyMTA2M2Y%3D"
                                  target="_blank"
                                >
                                  <img
                                    src={InstagramIcon.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.etsy.com/shop/HandleBrandCo"
                                  target="_blank"
                                >
                                  <img
                                    src={Etsy.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.tiktok.com/@handlegeorgia"
                                  target="_blank"
                                >
                                  <img
                                    src={TikTok.src}
                                    alt=""
                                  />
                                </a>
                              </li>
                            </>
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="foo-menu flex">
                  <div className="foo-navbar">
                    <h4>{intl.messages.sections}</h4>
                    <ul>
                      <li>
                        <Link href={"/all/1"}>{intl.messages.shop}</Link>
                      </li>
                      <li>
                        <Link href={"/discount/1"}>
                          {intl.messages.discountRange}
                        </Link>
                      </li>
                      <li>
                        <Link href={"/about"}>{intl.messages.aboutUs}</Link>
                      </li>
                      <li>
                        <Link href={"/contact"}>{intl.messages.contactUs}</Link>
                      </li>
                      <li>
                        <Link href={"/terms-of-use"}>
                          {intl.messages.termsOfUse}
                        </Link>
                      </li>
                      <li>
                        <Link href={"/faq"}>{intl.messages.faq}</Link>
                      </li>
                      <li>
                        <Link href={"/reviews"}>{intl.messages.reviews}</Link>
                      </li>
                      <li>
                        <Link href={"/blog"}>{intl.messages.blog}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="foo-navbar">
                    <h4>{intl.messages.chess}</h4>
                    <ul>
                      <>
                        {chessDataParse?.map((item, i) => (
                          <li key={i}>
                            <Link href={`/shop/1?&item=${item.id}`}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </>
                    </ul>
                  </div>
                  <div className="foo-navbar">
                    <h4>{intl.messages.backgammon}</h4>
                    <ul>
                      <>
                        {backgammonParse?.map((item, i) => (
                          <li key={i}>
                            <Link href={`/shop/1?&item=${item.id}`}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="foo-bottom flex flex-ai-center flex-jc-between">
                <p>Copyright © 2022 Handle.am </p>
                <ul className="payment-methods flex flex-ai-center">
                  <li className="mr-10">
                    <img
                      src={VisaIcon.src}
                      alt=""
                    />
                  </li>
                  <li className="mr-10">
                    <img
                      src={MasterCardIcon.src}
                      alt=""
                    />
                  </li>
                  <li className="mr-10">
                    <img
                      src={AmericanExpressIcon.src}
                      alt=""
                    />
                  </li>
                  <li>
                    <span>
                      <img
                        src={IdramLogo.src}
                        alt="Idram"
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
}
{
  /* {hostName === "handle.ge" && countryCode === "3" && (
                          <li>
                            <a
                              href="https://www.instagram.com/handle_georgia/?igshid=YmMyMTA2M2Y%3D"
                              target="_blank"
                            >
                              <img src={InstagramIcon.src} alt="" />
                            </a>
                          </li>
                        )}
                        {hostName === "handle-brand.com" && (
                          <li>
                            <a
                              href="https://instagram.com/handlebrand.usa?igshid=YmMyMTA2M2Y="
                              target="_blank"
                            >
                              <img src={InstagramIcon.src} alt="" />
                            </a>
                          </li>
                        )}
                        {hostName !== "handle-brand.com" &&
                          hostName !== "handle.ge" &&
                          country !== 3 && (
                            <li>
                              <a
                                href="https://instagram.com/handlebrand_armenia?igshid=YmMyMTA2M2Y="
                                target="_blank"
                              >
                                <img src={InstagramIcon.src} alt="" />
                              </a>
                            </li>
                          )} */
}
